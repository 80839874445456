<template>
    <div class="table-responsive-md">
        <div class="row select-invoice">
            <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    Дата:
                </p>
                <p class="item-text">
                    {{ elementServiceMin.elementDate | moment("DD.MM.YYYY") }}
                </p>
            </div>
            <div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    ACN:
                </p>
                <p class="item-text">
                    {{ elementServiceMin.acn }}
                </p>
            </div>
            <div class="col-12 col-md-6 mb-3 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    Первичный документ:
                </p>
                <p class="item-text" v-if="elementServiceMin.primaryDocumentCaption">
                    <button :id="'btn-primaryDocument-caption-'+elementServiceMin.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openPrimaryDocument(elementServiceMin.primaryDocumentId)">{{ elementServiceMin.primaryDocumentCaption }}</button>
                </p>
                <p class="item-text" v-if="!elementServiceMin.primaryDocumentCaption">
                    -
                </p>
            </div>
            <div class="col-12 col-md-6 col-lg-4 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    Наименование:
                </p>
                <p class="item-text">
                    {{ elementServiceMin.serviceName }}
                </p>
            </div>
            <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    Стоимость:
                </p>
                <p class="item-text">
                    {{ elementServiceMin.chargeAmount | formatMoney2 }}
                </p>
            </div>
            <div class="col-12 col-md-3 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    К оплате:
                </p>
                <p class="item-text">
                    {{ elementServiceMin.amount | formatMoney2 }}
                </p>
            </div>
            <div class="col-12 col-md-3 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    Сумма доп. коэф.:
                </p>
                <p class="item-text">
                    {{ elementServiceMin.factorAmount | formatMoney2 }}
                </p>
            </div>
            <div class="col-12 col-md-3 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                <p class="mr-2">
                    Сумма скидки:
                </p>
                <p class="item-text">
                    {{ elementServiceMin.discountAmount | formatMoney2 }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ElementService",
        props: ['elementServiceMin'],
        methods: {
            openPrimaryDocument(primaryDocumentId) {
                let route = this.$router.resolve({name: 'PrimaryDocsPrimaryDocument', params: {primaryDocumentId: primaryDocumentId}});
                window.open(route.href, '_blank');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-invoice {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
